import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import './UIDisponibilityCalendar.scss'

const format = 'YYYY-MM-DDT00:00:00+01:00'

require('dayjs/locale/fr')
dayjs.locale('fr')

export default function UIDisponibilityCalendar({
  service,
  onChange,
  displayDisponibility,
}) {
  const { t } = useTranslation('service')
  const [disponibility, setDisponibility] = useState([])
  const [currentDay, setCurrentDay] = useState()
  const [calandar, setCalandar] = useState([])
  const [allDays, setAllDays] = useState([])

  const calcRealTime = (time, currDay) => {
    let cD = dayjs()
    if (currDay) {
      cD = dayjs()
    }
    return dayjs(
      `${cD?.format('YYYY-MM-DDT')}${dayjs(time).format('HH:mm:ss')}`
    )
  }

  const calcSlot = (periods, currDay) => {
    const dispo = []

    periods.forEach(({ startDatetime: startTime, endDatetime: endTime }) => {
      const { bookingLength } = service
      const originalStartDateTime = calcRealTime(startTime, currDay)
      let startDatetime = calcRealTime(startTime, currDay).add(
        service.prepareDelay,
        'minute'
      )
      let endDatetime = calcRealTime(endTime, currDay)
      if (service.bookingType === 'EVENT') {
        const today = dayjs().format(format)
        if (
          (currDay === today && startDatetime > dayjs()) ||
          currDay !== today
        ) {
          dispo.push({
            startDatetime: startDatetime.format('HH:mm'),
            endDatetime: endDatetime.format('HH:mm'),
            available: true,
          })
        }
      } else if (bookingLength) {
        while (startDatetime < endDatetime) {
          const end = startDatetime.add(bookingLength, 'minute')
          const today = dayjs().format(format)
          if (
            (currDay === today && startDatetime > dayjs()) ||
            currDay !== today
          ) {
            dispo.push({
              startDatetime: startDatetime.format('HH:mm'),
              endDatetime: end.format('HH:mm'),
              available: true,
            })
          }
          startDatetime = end
        }
      } else {
        const today = dayjs().format(format)
        if (currDay === today) {
          if (startDatetime > dayjs()) {
            dispo.push({
              startDatetime: originalStartDateTime.format('HH:mm'),
              endDatetime: endDatetime.format('HH:mm'),
              available: true,
            })
          } else {
            const newStart = dayjs().add(
              60 + (60 - dayjs().format('mm')),
              'minutes'
            )
            if (newStart < endDatetime) {
              dispo.push({
                startDatetime: newStart.format('HH:mm'),
                endDatetime: endDatetime.format('HH:mm'),
                available: true,
              })
            }
          }
        } else {
          dispo.push({
            startDatetime: originalStartDateTime.format('HH:mm'),
            endDatetime: endDatetime.format('HH:mm'),
            available: true,
          })
        }
      }
    })
    if (dispo.length === 1) {
      dispo[0].available = false
      onChange({
        startDatetime: calcRealTime(
          `2018-11-12T${dispo[0].startDatetime}+01:00`,
          currDay
        ),
        endDatetime: calcRealTime(
          `2018-11-12T${dispo[0].endDatetime}+01:00`,
          currDay
        ),
      })
    } else if (dispo.length === 0 && currDay === allDays[0]?.value) {
      const cal = allDays.slice(1, 6)
      setCalandar(cal)
      setCurrentDay(cal[0]?.value)
    } else {
      onChange({ startDatetime: null, endDatetime: null })
    }
    setDisponibility(dispo)
    displayDisponibility(dispo)
  }

  const prepareCalendar = (startDatetime, endDatetime, days) => {
    let startDate = dayjs(dayjs(startDatetime).format(format))
    const endDate = dayjs(dayjs(endDatetime).format(format))
    const today = dayjs(dayjs().format(format))
    while (startDate <= endDate) {
      if (
        startDate >= today &&
        !days.find((ds) => ds.value === startDate.format(format))
      ) {
        let label = startDate.format('ddd DD MMMM YYYY')
        if (startDate.format('DDMMYYYY') === today.format('DDMMYYYY')) {
          label = t('today')
        } else if (
          startDate.format('DDMMYYYY') ===
          today.add('1', 'day').format('DDMMYYYY')
        ) {
          label = t('tomorrow')
        }

        days.push({
          value: startDate.format(format),
          label,
        })
      }
      startDate = startDate.add('1', 'day')
    }
    return days
  }

  const findSlot = (currDay) => {
    switch (service?.availabilityMode) {
      case 'PERMANENT':
        calcSlot(
          [
            {
              startDatetime: service.startDatetime,
              endDatetime: service.endDatetime,
            },
          ],
          currDay
        )
        break
      case 'TIMERANGE':
        const periods = []
        service.residenceServiceAvailabilityRangesByIdResidenceService.nodes.forEach(
          (node) => {
            if (
              (dayjs(currDay) > dayjs(node.startDatetime) &&
                dayjs(currDay) < dayjs(node.endDatetime)) ||
              dayjs(currDay).format('YYYYMMDD') ===
                dayjs(node.startDatetime).format('YYYYMMDD') ||
              dayjs(currDay).format('YYYYMMDD') ===
                dayjs(node.endDatetime).format('YYYYMMDD')
            ) {
              periods.push({
                startDatetime: node.startDatetime,
                endDatetime: node.endDatetime,
              })
            }
          }
        )
        calcSlot(periods, currDay)
        break
      case 'PERIODICAL':
        const period = []
        service.residenceServiceAvailabilityRangesByIdResidenceService.nodes.forEach(
          (node) => {
            if (
              dayjs(currDay) > dayjs(node.startDatetime) &&
              dayjs(currDay) < dayjs(node.endDatetime)
            ) {
              switch (node.serviceRepeatUnitByIdRepeatUnit?.name) {
                case 'day':
                  period.push({
                    startDatetime: node.startDatetime,
                    endDatetime: node.endDatetime,
                  })
                  break
                case 'week':
                  if (
                    (node.repeatParameterValue & 1 &&
                      dayjs(currDay).day() === 0) ||
                    (node.repeatParameterValue & 64 &&
                      dayjs(currDay).day() === 1) ||
                    (node.repeatParameterValue & 32 &&
                      dayjs(currDay).day() === 2) ||
                    (node.repeatParameterValue & 16 &&
                      dayjs(currDay).day() === 3) ||
                    (node.repeatParameterValue & 8 &&
                      dayjs(currDay).day() === 4) ||
                    (node.repeatParameterValue & 4 &&
                      dayjs(currDay).day() === 5) ||
                    (node.repeatParameterValue & 2 &&
                      dayjs(currDay).day() === 6)
                  ) {
                    if (
                      Math.abs(
                        dayjs(node.startDatetime).diff(dayjs(), 'week')
                      ) %
                        node.repeatValue ===
                      0
                    ) {
                      period.push({
                        startDatetime: node.startDatetime,
                        endDatetime: node.endDatetime,
                      })
                    }
                  }
                  break
                case 'month':
                  let verifAdd = false
                  if (
                    node.serviceRepeatParameterByIdRepeatParameter?.name ===
                    'day'
                  ) {
                    const today = dayjs(currDay).format(format)

                    const isToday = dayjs()
                      .startOf('month')
                      .add(node.repeatParameterValue - 1, 'day')
                      .format(format)

                    verifAdd = today === isToday
                  } else {
                    const startMonth = dayjs(currDay).startOf('month')
                    const today = dayjs(currDay)
                    if (
                      Math.abs(today.diff(startMonth, 'week')) ===
                      node.repeatParameterValue - 1
                    ) {
                      switch (
                        node.serviceRepeatParameterByIdRepeatParameter?.name
                      ) {
                        case 'monday':
                          verifAdd = dayjs(currDay).day() === 1
                          break
                        case 'tuesday':
                          verifAdd = dayjs(currDay).day() === 2
                          break
                        case 'wednesday':
                          verifAdd = dayjs(currDay).day() === 3
                          break
                        case 'thursday':
                          verifAdd = dayjs(currDay).day() === 4
                          break
                        case 'friday':
                          verifAdd = dayjs(currDay).day() === 5
                          break
                        case 'saturday':
                          verifAdd = dayjs(currDay).day() === 6
                          break
                        case 'sunday':
                          verifAdd = dayjs(currDay).day() === 0
                          break
                      }
                    }
                  }

                  if (
                    verifAdd &&
                    Math.abs(
                      dayjs(node.startDatetime).diff(dayjs(currDay), 'month')
                    ) %
                      node.repeatValue ===
                      0
                  ) {
                    period.push({
                      startDatetime: node.startDatetime,
                      endDatetime: node.endDatetime,
                    })
                  }
                  break
                case 'year':
                  if (
                    dayjs(node.startDatetime).format('YYYY-MM-DD') ===
                    dayjs(currDay).format('YYYY-MM-DD')
                  ) {
                    period.push({
                      startDatetime: node.startDatetime,
                      endDatetime: node.endDatetime,
                    })
                  }
                  break
                default:
                  break
              }
            }
          }
        )
        const realPeriods = []
        period.forEach((p) => {
          if (realPeriods.length === 0) {
            realPeriods.push(p)
          } else {
            let shouldAdd = true
            realPeriods.forEach((rp, index) => {
              if (
                !(
                  dayjs(p.startDatetime).format('HH:mm') >=
                    dayjs(rp.endDatetime).format('HH:mm') ||
                  dayjs(p.endDatetime).format('HH:mm') <=
                    dayjs(rp.startDatetime).format('HH:mm')
                )
              ) {
                shouldAdd = false
                if (
                  dayjs(p.startDatetime).format('HH:mm') <
                  dayjs(rp.startDatetime).format('HH:mm')
                ) {
                  realPeriods[index].startDatetime = p.startDatetime
                }
                if (
                  dayjs(p.endDatetime).format('HH:mm') >
                  dayjs(rp.endDatetime).format('HH:mm')
                ) {
                  shouldAdd = false
                  realPeriods[index].endDatetime = p.endDatetime
                }
              }
            })
            if (shouldAdd) {
              realPeriods.push(p)
            }
          }
        })
        realPeriods.sort(
          (a, b) =>
            dayjs(a.startDatetime).format('HHmm') -
            dayjs(b.startDatetime).format('HHmm')
        )
        calcSlot(realPeriods, currDay)
        break
      default:
        break
    }
  }

  useEffect(() => {
    if (service) {
      let days = []
      switch (service.availabilityMode) {
        case 'TIMERANGE':
          service.residenceServiceAvailabilityRangesByIdResidenceService.nodes.forEach(
            (node) => {
              days = prepareCalendar(node.startDatetime, node.endDatetime, days)
            }
          )
          break
        case 'PERIODICAL':
          let start
          let end
          service.residenceServiceAvailabilityRangesByIdResidenceService.nodes.forEach(
            (node) => {
              switch (node.serviceRepeatUnitByIdRepeatUnit?.name) {
                case 'day':
                  days = prepareCalendar(
                    node.startDatetime,
                    node.endDatetime,
                    days
                  )
                  break
                case 'week':
                  start = dayjs(node.startDatetime)
                  end = dayjs(node.endDatetime)
                  while (start < end) {
                    if (
                      (node.repeatParameterValue & 1 && start.day() === 0) ||
                      (node.repeatParameterValue & 64 && start.day() === 1) ||
                      (node.repeatParameterValue & 32 && start.day() === 2) ||
                      (node.repeatParameterValue & 16 && start.day() === 3) ||
                      (node.repeatParameterValue & 8 && start.day() === 4) ||
                      (node.repeatParameterValue & 4 && start.day() === 5) ||
                      (node.repeatParameterValue & 2 && start.day() === 6)
                    ) {
                      if (
                        Math.abs(start.diff(dayjs(), 'week')) %
                          node.repeatValue ===
                        0
                      ) {
                        days = prepareCalendar(
                          start.format('YYYY-MM-DDTHH:mm:00+01:00'),
                          `${start.format('YYYY-MM-DD')}T${end.format(
                            'HH:mm'
                          )}:00+01:00`,
                          days
                        )
                      }
                    }
                    start = start.add('1', 'day')
                  }
                  break
                case 'month':
                  start = dayjs(node.startDatetime)
                  end = dayjs(node.endDatetime)
                  while (start < end) {
                    let verifAdd = false
                    if (
                      node.serviceRepeatParameterByIdRepeatParameter?.name ===
                      'day'
                    ) {
                      const today = dayjs(start).format(format)

                      const isToday = dayjs()
                        .startOf('month')
                        .add(node.repeatParameterValue - 1, 'day')
                        .format(format)

                      verifAdd = today === isToday
                    } else {
                      const startMonth = dayjs(start).startOf('month')
                      const today = dayjs(start)
                      if (
                        Math.abs(today.diff(startMonth, 'week')) ===
                        node.repeatParameterValue - 1
                      ) {
                        switch (
                          node.serviceRepeatParameterByIdRepeatParameter?.name
                        ) {
                          case 'monday':
                            verifAdd = dayjs(start).day() === 1
                            break
                          case 'tuesday':
                            verifAdd = dayjs(start).day() === 2
                            break
                          case 'wednesday':
                            verifAdd = dayjs(start).day() === 3
                            break
                          case 'thursday':
                            verifAdd = dayjs(start).day() === 4
                            break
                          case 'friday':
                            verifAdd = dayjs(start).day() === 5
                            break
                          case 'saturday':
                            verifAdd = dayjs(start).day() === 6
                            break
                          case 'sunday':
                            verifAdd = dayjs(start).day() === 0
                            break
                        }
                      }
                    }

                    if (
                      verifAdd &&
                      Math.abs(
                        dayjs(node.startDatetime).diff(dayjs(start), 'month')
                      ) %
                        node.repeatValue ===
                        0
                    ) {
                      days = prepareCalendar(
                        start.format('YYYY-MM-DDTHH:mm:00+01:00'),
                        `${start.format('YYYY-MM-DD')}T${end.format(
                          'HH:mm'
                        )}:00+01:00`,
                        days
                      )
                    }
                    start = start.add('1', 'day')
                  }
                  break
                case 'year':
                  if (
                    dayjs(node.startDatetime).format('YYYY-MM-DD') ===
                    dayjs(currentDay).format('YYYY-MM-DD')
                  ) {
                    days = prepareCalendar(
                      node.startDatetime,
                      node.endDatetime,
                      days
                    )
                  }
                  break
                default:
                  break
              }
            }
          )
          break
        default:
          if (service.startDatetime && service.endDatetime) {
            days = prepareCalendar(
              service.startDatetime,
              service.endDatetime,
              days
            )
          }
          break
      }
      if (days) {
        const orderedDays = days.sort((a, b) => a.value - b.value)
        setAllDays(orderedDays)
        const cal = orderedDays.slice(0, 5)
        setCalandar(cal)
        setCurrentDay(cal[0]?.value)
        findSlot(cal[0]?.value)
      }
    }
  }, [service])

  return (
    <div className="uiDisponibilityCalendar">
      {calandar.length > 0 && (
        <div className="uiDisponibilityCalendar_selectContainer">
          {calandar.length > 1 ? (
            <select
              className="uiDisponibilityCalendar_select"
              value={currentDay}
              onChange={(e) => {
                setCurrentDay(e.currentTarget.value)
                findSlot(e.currentTarget.value)
              }}
            >
              {calandar.map((day, index) => (
                <option key={`optDay${index}`} value={day.value}>
                  {day.label}
                </option>
              ))}
            </select>
          ) : (
            <p>{calandar[0].label}</p>
          )}
        </div>
      )}
      <div className="uiDisponibilityCalendar_date">
        {disponibility.map((timeSlot, index) => (
          <div
            className="uiDisponibilityCalendar_timeSlotContainer"
            key={`timeSlot${index}`}
          >
            <button
              disabled={!timeSlot.available}
              onClick={() => {
                onChange({
                  startDatetime: calcRealTime(
                    `2018-11-12T${timeSlot.startDatetime}+01:00`
                  ),
                  endDatetime: calcRealTime(
                    `2018-11-12T${timeSlot.endDatetime}+01:00`
                  ),
                })
                const ts = [
                  ...disponibility.map((d) => ({
                    ...d,
                    available: true,
                  })),
                ]
                ts[index].available = false
                setDisponibility(ts)
              }}
              className={
                timeSlot.available
                  ? 'uiDisponibilityCalendar_timeSlot'
                  : 'uiDisponibilityCalendar_timeSlot uiDisponibilityCalendar_timeSlot_disabled'
              }
            >
              {timeSlot.startDatetime} - {timeSlot.endDatetime}
            </button>
          </div>
        ))}
        {disponibility.length === 0 && <p>{t('noAvailibity')}</p>}
      </div>
    </div>
  )
}

UIDisponibilityCalendar.defaultProps = {
  onChange: () => {},
  service: {},
  displayDisponibility: () => {},
}

UIDisponibilityCalendar.propTypes = {
  service: PropTypes.objectOf(PropTypes.any),
  onChange: PropTypes.func,
  displayDisponibility: PropTypes.func,
}
