import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { initSentry } from './lib/sentry'
import {
  ContactForm,
  Faq,
  ForgotPassword,
  Home,
  IncidentForm,
  Landing,
  Login,
  MyAccount,
  Reservations,
  ResetPassword,
  Services,
} from './pages'
import './styles/styles.scss'

import PrivateRoute from './components/_Widget/WidgetPrivateRoute/WidgetPrivateRoute'
import MyResidence from './pages/MyResidence/MyResidence'

initSentry()

export default function App() {
  return (
    // eslint-disable-next-line no-undef
    <Router basename={process.env.REACT_APP_BASENAME || ''}>
      <div className="app">
        <Switch>
          <Route exact path="/" component={Landing} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/forgot-password" component={ForgotPassword} />
          <Route exact path="/reset-password" component={ResetPassword} />
          <PrivateRoute
            exact
            path="/home"
            component={Home}
            access={['logifim_resident', 'logifim_external']}
          />
          <PrivateRoute
            exact
            path="/my-account"
            component={MyAccount}
            access={['logifim_resident', 'logifim_external']}
          />
          <PrivateRoute
            exact
            path="/my-residence"
            component={MyResidence}
            access={['logifim_resident', 'logifim_external']}
          />
          <PrivateRoute
            exact
            path="/services/:id"
            component={Services}
            access={['logifim_resident', 'logifim_external']}
          />
          <PrivateRoute
            exact
            path="/incident-form"
            component={IncidentForm}
            access={['logifim_resident', 'logifim_external']}
          />
          <PrivateRoute
            exact
            path="/contact-form"
            component={ContactForm}
            access={['logifim_resident', 'logifim_external']}
          />
          <PrivateRoute
            exact
            path="/faq"
            component={Faq}
            access={['logifim_resident', 'logifim_external']}
          />
          <PrivateRoute
            exact
            path="/reservations"
            component={Reservations}
            access={['logifim_resident', 'logifim_external']}
          />
        </Switch>
      </div>
    </Router>
  )
}
