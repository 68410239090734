import { useMutation, useQuery } from '@apollo/client'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Contact as ContactGql } from '../../../gql'
import { getUserId } from '../../../lib/helpers'
import { captureMessageWithAttachment } from '../../../lib/sentry'
import UIButton from '../../_UI/UIButton/UIButton'
import UISelect from '../../_UI/UISelect/UISelect'
import UITextArea from '../../_UI/UITextArea/UITextArea'
import './WidgetFormContact.scss'

export default function WidgetFormContact({
  placeholder,
  dsc,
  fromIncident,
  validationMessage,
}) {
  const { t } = useTranslation('incidentForm')
  const access_token = localStorage.getItem('access_token')
  const idUser = getUserId(access_token)
  //const infoUser = getUserRole(access_token)
  const [count, setCount] = useState(0)
  const [error, setError] = useState(false)
  const [message, setMessage] = useState('')
  const [motif, setMotif] = useState('')
  const [send, setSend] = useState(false)
  const [optionContact, setOptionContact] = useState()

  const countCharacter = (event) => {
    setCount(event.target.value.length)
    setMessage(event.target.value)
    if (event.target.value.length === 1500) {
      setError(true)
    }
  }

  const { data: resident } = useQuery(ContactGql.getInternalReasons, {
    fetchPolicy: 'network-only',
    onCompleted: (response) => {
      console.log(response.internalReasons.nodes)
      setOptionContact(response?.internalReasons?.nodes)
      setMotif(response?.internalReasons?.nodes[0]?.id)
    },
    onError: (err) => {
      const request = ContactGql.getInternalReasons
      const event = {
        request: request?.definitions[0]?.name?.value,
        variables: {
          id: resident?.resident?.idResidence,
        },
      }
      captureMessageWithAttachment(event, err)
    },
  })

  const [createInternalMessage] = useMutation(
    ContactGql.createInternalMessage,
    {
      variables: {
        content: message,
        idReason: motif,
        idSender: idUser,
        idRecipient: '5975698e-c1a2-45fa-b2e2-15f1d97c085a',
      },
      onCompleted: () => {
        setSend(true)
      },
      onError: (err) => {
        const event = {
          request:
            ContactGql.createInternalMessage?.definitions[0]?.name?.value,
          variables: {
            content: message,
            idReason: motif,
            idSender: idUser,
            idRecipient: '5975698e-c1a2-45fa-b2e2-15f1d97c085a',
          },
        }
        captureMessageWithAttachment(event, err)
      },
    }
  )

  const sendIncident = () => {
    if (fromIncident) {
      console.log('send', message, motif)
    } else {
      console.log('question', motif, message)
      createInternalMessage()
    }
  }

  return (
    <div className="widgetFormContact">
      {send && (
        <p
          style={{
            fontWeight: 700,
            fontSize: 16,
            color: 'green',
          }}
        >
          {validationMessage}
        </p>
      )}
      <div style={{ visibility: send ? 'hidden' : 'visible' }}>
        {console.log(motif)}
        {optionContact && (
          <UISelect
            size="full"
            options={optionContact}
            onChange={(e) => setMotif(e.target.value)}
            value={motif}
          />
        )}

        <UITextArea
          placeholder={placeholder}
          onChange={(event) => {
            countCharacter(event)
          }}
          value={count}
        />
        <p className="widgetFormContact_count">
          {`${t('limit')} 
          ${count} ${t(t('1500'))}`}
        </p>
        {error && <p>{t('error')}</p>}
        <p className="widgetFormContact_dsc">{dsc}</p>
        <UIButton
          label={t('btnLabel')}
          size="full"
          backgroundColor="bgGreen"
          color="white"
          onClick={sendIncident}
        />
      </div>
    </div>
  )
}

WidgetFormContact.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  placeholder: PropTypes.string.isRequired,
  dsc: PropTypes.string.isRequired,
  fromIncident: PropTypes.bool.isRequired,
  validationMessage: PropTypes.string.isRequired,
}
