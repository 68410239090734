import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import './UIServiceWeb.scss'

export default function UIServiceWeb({ webUrl }) {
  const { t } = useTranslation('service')
  return (
    <div className="uiServiceWeb">
      <p className="uiServiceWeb_title">{t('webSite')}</p>
      <p className="uiServiceWeb_url">
        <a href={webUrl} target="_blank" rel="noopener noreferrer">
          {webUrl}
        </a>
      </p>
    </div>
  )
}

UIServiceWeb.propTypes = {
  webUrl: PropTypes.string,
}
