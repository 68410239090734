import { useMutation, useQuery } from '@apollo/client'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import LoadingSpin from 'react-loading-spin'
import Modal from 'react-modal'
import api from '../../api'
import Reservation from '../../components/Reservation/Reservation'
import UIBackButton from '../../components/_UI/UIBackButton/UIBackButton'
import UIChevronButton from '../../components/_UI/UIChevronButton/UIChevronButton'
import WidgetHeader from '../../components/_Widget/WidgetHeader/WidgetHeader'
import { Service as ServiceGql } from '../../gql'
import { getUserId } from '../../lib/helpers'
import './Reservations.scss'

const Reservations = () => {
  const { t } = useTranslation('reservation')

  const [myReservations, setMyReservations] = useState([])
  const [myCommands, setMyCommands] = useState([])
  const [openedIndex, setOpenedIndex] = useState(null)
  const [bookingOpen, setBookingOpen] = useState(true)
  const [orderOpen, setOrderOpen] = useState(true)
  const access_token = localStorage.getItem('access_token')
  const [loadingCommands, setLoadingCommands] = useState(false)

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      borderWidth: 0,
    },
  }

  const { data: bookingData, loading } = useQuery(
    ServiceGql.fetchBookingsByResident,
    {
      fetchPolicy: 'network-only',
      variables: {
        id: getUserId(access_token),
      },
      onCompleted: () => {
        setLoadingCommands(true)
        const data = [...bookingData.residenceServiceBookings.nodes].sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        )
        data.map((item) => {
          if (item.paymentsByIdOrder.nodes.length > 0) {
            api
              .getStripeTicket(
                item.paymentsByIdOrder.nodes[0].id,
                localStorage.getItem('access_token')
              )
              .then((res) => {
                {
                  if (!item.orderArticlesByIdOrder?.nodes?.length)
                    setMyReservations((prev) => [
                      ...prev,
                      { ...item, stripeTicket: res.stripe_receipt },
                    ])
                  else
                    setMyCommands((prev) => [
                      ...prev,
                      { ...item, stripeTicket: res.stripe_receipt },
                    ])
                }
              })
          } else {
            if (!item.orderArticlesByIdOrder?.nodes?.length)
              setMyReservations((prev) => [...prev, item])
            else setMyCommands((prev) => [...prev, item])
          }
        })
        setLoadingCommands(false)
      },
    }
  )

  const [cancel] = useMutation(ServiceGql.cancelResidenceServiceBooking)

  const cancelBooking = (id, index, order) => {
    cancel({
      variables: {
        id,
      },
    })
    if (!order) {
      const bookings = [...myReservations]
      bookings[index].status = 'ABONDONNED'
      setMyReservations(bookings)
    } else {
      const bookings = [...myCommands]
      bookings[index].status = 'ABONDONNED'
      setMyCommands(bookings)
    }
  }

  return (
    <>
      {loadingCommands ||
        (loading && (
          <Modal isOpen={true} style={customStyles}>
            <LoadingSpin primaryColor="#BCCF00" secondaryColor="white" />
          </Modal>
        ))}
      <div className="reservations">
        <WidgetHeader />
        <UIBackButton onClick={() => window.history.back()} />

        <div className="myReservations">
          <UIChevronButton
            onClick={() => setBookingOpen((prev) => !prev)}
            open={bookingOpen}
            title={t('myReservation')}
            color={'#0f326e'}
          />
          {bookingOpen && (
            <div className="myReservations_items">
              {myReservations.length > 0 ? (
                myReservations.map((res, index) => {
                  if (myReservations.hide) {
                    return <></>
                  }
                  return (
                    <Reservation
                      key={`reservations${index}`}
                      reservation={res}
                      display={() => {
                        const nts = [...myReservations]
                        nts[index].hide = true
                        setMyReservations(nts)
                      }}
                      opened={() => {
                        const nts = [
                          ...myReservations.map((n) => ({
                            ...n,
                            opened: false,
                          })),
                        ]
                        if (index !== openedIndex) {
                          nts[index].opened = true
                          setOpenedIndex(index)
                        } else {
                          setOpenedIndex(null)
                        }
                        setMyReservations(nts)
                      }}
                      cancel={(id) => {
                        cancelBooking(id, index, false)
                      }}
                    />
                  )
                })
              ) : (
                <p className="myReservations_text">{t('noReservation')}</p>
              )}
            </div>
          )}
        </div>

        <div className="myCommands">
          <UIChevronButton
            onClick={() => setOrderOpen((prev) => !prev)}
            open={orderOpen}
            title={t('myCommands')}
            color={'#0f326e'}
          />
          {orderOpen && (
            <div className="myCommands_items">
              {myCommands.length > 0 ? (
                myCommands
                  .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                  .map((res, index) => {
                    if (myCommands.hide) {
                      return <></>
                    }
                    return (
                      <Reservation
                        key={`reservations${index}`}
                        reservation={res}
                        stripeTicket={res.stripeTicket}
                        display={() => {
                          const nts = [...myCommands]
                          nts[index].hide = true
                          setMyCommands(nts)
                        }}
                        opened={() => {
                          const nts = [
                            ...myCommands.map((n) => ({
                              ...n,
                              opened: false,
                            })),
                          ]
                          if (index !== openedIndex) {
                            nts[index].opened = true
                            setOpenedIndex(index)
                          } else {
                            setOpenedIndex(null)
                          }
                          setMyCommands(nts)
                        }}
                        cancel={(id) => {
                          cancelBooking(id, index, true)
                        }}
                      />
                    )
                  })
              ) : (
                <p className="myCommands_text">{t('noCommands')}</p>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default Reservations
