import PropTypes from 'prop-types'
import './UIServiceDescription.scss'

export default function UIServiceDescription({ description }) {
  return (
    <div className="uiServiceDescription">
      <div className="uiServiceDescription_descriptionContainer">
        <p className="uiServiceDescription_description">{description}</p>
      </div>
    </div>
  )
}

UIServiceDescription.propTypes = {
  description: PropTypes.string.isRequired,
}
