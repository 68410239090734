import { useMutation } from '@apollo/client'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useAlert } from 'react-alert'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { useTranslation } from 'react-i18next'
import LoadingSpin from 'react-loading-spin'
import Modal from 'react-modal'
import { useParams } from 'react-router-dom'
import { useLocation } from 'react-router-dom/cjs/react-router-dom'
import api from '../../../api'
import config from '../../../config'
import { Service } from '../../../gql'
import { getUserId, getUserRole } from '../../../lib/helpers'
import { captureMessageWithAttachment } from '../../../lib/sentry'
import PaymentForm from '../../PaymentForm/PaymentForm'
import UIButton from '../../_UI/UIButton/UIButton'
import UIDisponibilityCalendar from '../../_UI/UIDisponibilityCalendar/UIDisponibilityCalendar'
import UITextArea from '../../_UI/UITextArea/UITextArea'
import './WidgetServiceDisponibility.scss'

const WidgetServiceDisponibility = ({
  service,
  quantities,
  paymentSuccess,
  total,
  idExternal,
}) => {
  const { t } = useTranslation('service')
  const [comments, setComments] = useState()
  const [timeSlot, setTimeSlot] = useState()
  const [created, setCreated] = useState(false)
  const [submit, setSubmit] = useState(false)
  const { id: idResidenceService } = useParams()
  const access_token = localStorage.getItem('access_token')
  const infoUser = getUserRole(access_token)
  const idUser = getUserId(access_token)
  const [startDate, setStartDate] = useState()
  const [showPaymentModal, setShowPaymentModal] = useState(true)
  const [clientSecret, setClientSecret] = useState()
  const [cancelCommand, setCancelCommand] = useState(false)
  const [loadingPayment, setIsLoadingPayment] = useState(false)
  const [paymentSuccessfull, setPaymentSuccessFull] = useState(false)
  const location = useLocation()
  const alert = useAlert()
  const [cancel] = useMutation(Service.cancelResidenceServiceBooking)
  const [disponibility, setDisponibility] = useState([])

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      borderWidth: 0,
    },
  }

  useEffect(() => {
    if (location.search && location.search !== '') setCreated(true)
  }, [location])

  let submitGraphQl = () => {}
  let mutationLoading
  let mutationError

  const [createOrderArticle] = useMutation(Service.createOrderArticle)

  const linkArticlesToOrder = (id) => {
    let articles
    if (infoUser === 'logifim_resident') {
      articles =
        service?.catalogByIdResidentCatalogue?.catalogArticlesByIdCatalog?.nodes
    } else if (infoUser === 'logifim_external') {
      articles =
        service?.residenceService?.catalogByIdExternalCatalogue
          ?.catalogArticlesByIdCatalog?.nodes
    }

    for (let index = 0; index < articles.length; index++) {
      const article = articles[index]
      if (quantities[index]) {
        createOrderArticle({
          variables: {
            idOrder: id,
            idArticle: article.articleByIdArticle.id,
            quantity: quantities[index],
          },
        }).then(() =>
          api
            .fetchPaymentIntent(id, localStorage.getItem('access_token'))
            .then((data) => {
              setCreated(true)
              setClientSecret(data.stripe_client_secret)
            })
            .catch(() => {
              cancel({
                variables: {
                  id: id,
                },
              })
              alert.show(
                <div style={{ color: 'red', textAlign: 'center' }}>
                  {t('webServiceError')}
                </div>,
                {
                  timeout: 5000,
                  containerStyle: {
                    backgroundColor: '#fff',
                  },
                }
              )
            })
            .finally(() => setIsLoadingPayment(false))
        )
      }
    }
  }

  let startDatetime = timeSlot?.startDatetime?.format(
    'YYYY-MM-DDTHH:mm:ss+01:00'
  )

  let endDatetime = timeSlot?.endDatetime?.format('YYYY-MM-DDTHH:mm:ss+01:00')

  if (!timeSlot && startDate) {
    startDatetime = dayjs(startDate).format('YYYY-MM-DDT00:00:00+01:00')
    endDatetime = dayjs(startDate).format('YYYY-MM-DDT23:59:59+01:00')
  }

  const submitGQL = useMutation(Service.createResidenceServiceBooking, {
    variables: {
      idResidenceService,
      startDatetime: startDatetime || dayjs(),
      endDatetime: endDatetime || dayjs().add('1', 'hours'),
      comments,
      idResident: infoUser === 'logifim_resident' ? idUser : null,
      idExternal: idExternal
        ? idExternal
        : infoUser === 'logifim_external'
        ? idUser
        : null,
    },
    onCompleted: (data) => {
      setIsLoadingPayment(true)
      if (total === 0) {
        setIsLoadingPayment(false)
        setCreated(true)
        setPaymentSuccessFull(true)
      }

      if (!quantities.every((item) => item === 0)) {
        let haveCatalogue
        if (infoUser === 'logifim_resident') {
          haveCatalogue =
            service?.catalogByIdResidentCatalogue?.catalogArticlesByIdCatalog
              ?.nodes?.length > 0
        } else if (infoUser === 'logifim_external') {
          haveCatalogue =
            service?.residenceService?.catalogByIdExternalCatalogue
              ?.catalogArticlesByIdCatalog?.nodes.length > 0
        }
        if (haveCatalogue) {
          linkArticlesToOrder(
            data.createResidenceServiceBooking.residenceServiceBooking.id
          )
        }
      }
    },
    onError: (err) => {
      const request = Service.createResidenceServiceBooking
      const event = {
        request: request?.definitions[0]?.name?.value,
        variables: {
          idResidenceService,
          startDatetime,
          endDatetime,
          comments,
          idResident: infoUser === 'logifim_resident' ? idUser : null,
          idExternal: infoUser === 'logifim_external' ? idUser : null,
        },
      }
      captureMessageWithAttachment(event, err)
    },
  })
  submitGraphQl = submitGQL[0]
  mutationLoading = submitGQL[1].loading
  mutationError = submitGQL[1].error

  const closeModal = () => {
    setShowPaymentModal(false)
    setCancelCommand(true)
  }

  const PaymentSuccessFull = () => {
    setShowPaymentModal(false)
    setPaymentSuccessFull(true)
    paymentSuccess()
  }

  return (
    <>
      {loadingPayment && (
        <Modal isOpen={loadingPayment} style={customStyles}>
          <LoadingSpin primaryColor="#BCCF00" secondaryColor="white" />
        </Modal>
      )}
      {clientSecret && created && (
        <PaymentForm
          showModal={showPaymentModal}
          onCloseModal={closeModal}
          clientSecret={clientSecret}
          closeModalPayment={PaymentSuccessFull}
        />
      )}
      {!created ? (
        <>
          <div className="widgetServiceDisponibility">
            <p className="widgetServiceDisponibility_title">
              {t('availibity')}
            </p>
            <UIDisponibilityCalendar
              service={service}
              onChange={(ts) => {
                setSubmit(false)
                setTimeSlot(ts)
              }}
              displayDisponibility={setDisponibility}
            ></UIDisponibilityCalendar>
          </div>
          <div className="widgetServiceDisponibility_commentContainer">
            {service?.isCommentedBooking && (
              <>
                <p className="widgetServiceDisponibility_title">
                  {t('comments')}
                </p>
                <UITextArea
                  className="widgetServiceDisponibility_textarea"
                  onChange={(event) => {
                    setComments(event.target.value)
                  }}
                ></UITextArea>
              </>
            )}
            {false && service.bookingType === 'EVENT' && (
              <div style={{ position: 'relative' }}>
                <DatePicker
                  selected={startDate}
                  dateFormat={t('dateFormat')}
                  minDate={new Date()}
                  onChange={(date) => setStartDate(date)}
                />
                <img
                  src={`${config.REACT_APP_PREFIX}/images/calandar.png`}
                  alt="warning"
                  style={{
                    width: '20px',
                    position: 'absolute',
                    top: '8px',
                    right: '5px',
                  }}
                />
              </div>
            )}
            {false && (
              <div className="widgetServiceDisponibility_warning">
                <img
                  src={`${config.REACT_APP_PREFIX}/images/warning.png`}
                  alt="warning"
                  style={{ width: '25px' }}
                />
                <p>{t('warning')}</p>
              </div>
            )}
            <UIButton
              color="white"
              backgroundColor="bgGreen"
              label={mutationLoading ? t('loading') : t('button')}
              size="full"
              disabled={
                service.catalogByIdExternalCatalogue === null &&
                service.catalogByIdResidentCatalogue === null
                  ? false
                  : disponibility.length === 0 ||
                    mutationLoading ||
                    quantities.every((item) => item === 0)
              }
              onClick={() => {
                if (total === 0) {
                  setIsLoadingPayment(true)
                  setSubmit(true)
                  submitGraphQl()
                } else {
                  setSubmit(true)
                  setShowPaymentModal(true)
                  if (!mutationLoading && timeSlot?.startDatetime) {
                    submitGraphQl()
                  } else if (
                    service?.availabilityMode === 'PERMANENT' &&
                    !mutationLoading
                  ) {
                    submitGraphQl()
                  }
                }
              }}
            />
            {mutationError && <p>{t('error')}</p>}
            {submit && !timeSlot?.startDatetime && <p>{t('noSlot')}</p>}
          </div>
        </>
      ) : created && paymentSuccessfull && !cancelCommand ? (
        <p className="widgetServiceDisponibility_confirmation">{t('mail')}</p>
      ) : (
        cancelCommand && (
          <p className="widgetServiceDisponibility_confirmation">
            {t('cancel')}
          </p>
        )
      )}
    </>
  )
}

WidgetServiceDisponibility.defaultProps = {
  service: {},
}

WidgetServiceDisponibility.propTypes = {
  service: PropTypes.objectOf(PropTypes.any),
  webUrl: PropTypes.string,
  quantities: PropTypes.arrayOf(PropTypes.number).isRequired,
  paymentSuccess: PropTypes.func,
  total: PropTypes.number,
  idExternal: PropTypes.string,
}

export default WidgetServiceDisponibility
