import PropTypes from 'prop-types'
import './UIButton.scss'

export default function UIButton({
  label,
  kind = 'primary',
  size = 'medium',
  backgroundColor = 'green',
  color = 'white',
  disabled = false,
  onClick,
  type = 'button',
  ...props
}) {
  return (
    <button
      type={type}
      className={`uiButton uiButton--${size} uiButton--${backgroundColor} uiButton--${color} uiButton--${kind}`}
      disabled={disabled}
      {...props}
      onClick={onClick}
    >
      {label}
    </button>
  )
}

UIButton.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onClick: PropTypes.func.isRequired,
  kind: PropTypes.oneOf(['primary', 'secondary']),
  size: PropTypes.oneOf(['small', 'medium', 'large', 'full']),
  backgroundColor: PropTypes.oneOf([
    'bgGreen',
    'bgRed',
    'bgTransparent',
    'bgGray',
    'bgWhite',
    'bgBlue',
  ]),
  color: PropTypes.oneOf(['white', 'black', 'gray', 'green', 'red']),
  type: PropTypes.string,
}
