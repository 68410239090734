import React from 'react'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import LayoutModal from '../_Layout/LayoutModal/LayoutModal'
import PropTypes from 'prop-types'
import config from '../../config'

import CheckoutForm from './CheckoutForm'
import './PaymentForm.scss'

const stripePromise = loadStripe(config.STRIPE_PUBLISH_KEY)

export default function PaymentForm({
  showModal,
  onCloseModal,
  clientSecret,
  redirectUri,
  closeModalPayment,
}) {
  const appearance = {
    theme: 'stripe',
  }
  const options = {
    clientSecret,
    appearance,
  }

  return (
    <LayoutModal visible={showModal} onClose={onCloseModal}>
      {clientSecret ? (
        <div className="payment_content">
          <Elements options={options} stripe={stripePromise}>
            <CheckoutForm
              clientSecret={clientSecret}
              redirectUri={redirectUri}
              closeModalPayment={closeModalPayment}
            />
          </Elements>
        </div>
      ) : (
        <p>loading</p>
      )}
    </LayoutModal>
  )
}

PaymentForm.propTypes = {
  showModal: PropTypes.bool,
  onCloseModal: PropTypes.func,
  clientSecret: PropTypes.string,
  redirectUri: PropTypes.string,
  closeModalPayment: PropTypes.func,
}
