import { PropTypes } from 'prop-types'
import './LayoutModal.scss'

export default function LayoutModal({
  children,
  visible,
  onClose,
  closable = true,
}) {
  return (
    <div className={`layoutModal ${visible ? '' : 'layoutModal-hide'}`}>
      {visible && (
        <>
          <div className="layoutModal_container">
            <div className="layoutModal_overlay" onClick={onClose} />
            <div className="layoutModal_content">
              {closable && (
                <div className="layoutModal_closeContainer">
                  <div className="layoutModal_close" onClick={onClose}>
                    x
                  </div>
                </div>
              )}

              {children}
            </div>
          </div>
        </>
      )}
    </div>
  )
}

LayoutModal.propTypes = {
  children: PropTypes.node.isRequired,
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  closable: PropTypes.bool,
}
