import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import LoadingSpin from 'react-loading-spin'
import UIButton from '../_UI/UIButton/UIButton'
import './PaymentForm.scss'

export default function CheckoutForm({ clientSecret, closeModalPayment }) {
  const { t } = useTranslation('payment')
  const stripe = useStripe()
  const elements = useElements()

  const [message, setMessage] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [loadingForm, setLoaDingForm] = useState(false)

  useEffect(() => {
    setLoaDingForm(true)
    if (!stripe) {
      return
    }

    if (!clientSecret) {
      return
    }

    stripe
      .retrievePaymentIntent(clientSecret)
      .then(({ paymentIntent }) => {
        switch (paymentIntent.status) {
          case 'succeeded':
            setMessage(t('paymentSucceed'))
            break
          case 'processing':
            setMessage(t('processing'))
            break
          case 'error':
            setMessage(t('error'))
            break
        }
      })
      .finally(() => setLoaDingForm(false))
  }, [stripe])

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (!stripe || !elements) {
      return
    }

    setIsLoading(true)

    await stripe
      .confirmPayment({
        elements,
        redirect: 'if_required',
      })
      .then(({ paymentIntent, error }) => {
        if (error) {
          setMessage(t('error'))
        } else if (paymentIntent.status === 'succeeded') {
          closeModalPayment()
        }
      })
      .finally(() => setIsLoading(false))
  }

  return (
    <>
      {!loadingForm ? (
        <form id="payment-form" onSubmit={handleSubmit}>
          <PaymentElement id="payment-element" className="payment_element" />
          <div className="submit_button">
            <UIButton
              disabled={isLoading || !stripe || !elements}
              id="submit"
              label={t('payNow')}
              backgroundColor="bgBlue"
              color="white"
              type="submit"
            />
          </div>
          {message && <div id="payment-message">{message}</div>}
        </form>
      ) : (
        <LoadingSpin primaryColor="#BCCF00" secondaryColor="white" />
      )}
    </>
  )
}

CheckoutForm.propTypes = {
  clientSecret: PropTypes.string,
  redirectUri: PropTypes.string,
  closeModalPayment: PropTypes.func,
}
