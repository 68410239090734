import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import './MyResidenceForm.scss'

import UIButton from '../_UI/UIButton/UIButton'

export default function MyResidenceForm({
  lastnameValue,
  firstnameValue,
  emailValue,
  phoneNumberValue,
  downloadResidentRules,
  downloadResidentUseConditions,
}) {
  const { t } = useTranslation('myResidence')

  return (
    <div className="personalInformations">
      <p className="personalInformations_title">{t('siteManager')}</p>
      <div className="personalInformations_input">
        <p>{`${lastnameValue} ${firstnameValue}`}</p>
      </div>
      <div className="personalInformations_input">
        <p>{`${t('placeHolderEmail')} : ${emailValue}`}</p>
      </div>
      <div className="personalInformations_input">
        <p>{`${t('placeHolderPhoneNumber')} : ${phoneNumberValue}`}</p>
      </div>
      <p className="personalInformations_title">{t('residentDocuments')}</p>
      <div className="downloadButtonContent">
        <UIButton
          size="full"
          label={
            <div className="downloadButton">
              <p className="buttonText">{t('residentRules')}</p>
              <img src="/images/downloadLogo.svg" />
            </div>
          }
          onClick={downloadResidentRules}
        />
        <UIButton
          size="full"
          label={
            <div className="downloadButton">
              <p className="buttonText">{t('residentUseConditions')}</p>
              <img src="/images/downloadLogo.svg" />
            </div>
          }
          onClick={downloadResidentUseConditions}
        />
      </div>
    </div>
  )
}
MyResidenceForm.propTypes = {
  lastnameValue: PropTypes.string.isRequired,
  firstnameValue: PropTypes.string.isRequired,
  emailValue: PropTypes.string.isRequired,
  phoneNumberValue: PropTypes.string.isRequired,
  downloadResidentRules: PropTypes.func,
  downloadResidentUseConditions: PropTypes.func,
}
