import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import jwtDecode from 'jwt-decode'
import { isAuthorized } from '../../../lib/helpers'

const PrivateRoute = ({ component: Component, access, ...rest }) => {
  const token = localStorage.getItem('access_token')
  let redirectPath = '/home'
  if (!token) {
    redirectPath = '/login'
  }

  return (
    <Route
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      render={(props) =>
        // eslint-disable-next-line react/jsx-props-no-spreading
        localStorage.getItem('access_token') &&
        isAuthorized(
          jwtDecode(localStorage.getItem('access_token'))?.role,
          access
        ) ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: `${redirectPath}` }} />
        )
      }
    />
  )
}

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node,
    PropTypes.string,
    PropTypes.any,
  ]).isRequired,
  access: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node,
    PropTypes.string,
    PropTypes.any,
  ]).isRequired,
}

export default PrivateRoute
