import { useQuery } from '@apollo/client'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import api from '../../api'
import UIBackButton from '../../components/_UI/UIBackButton/UIBackButton'
import UIServiceDescription from '../../components/_UI/UIServiceDescription/UIServiceDescription'
import UIServiceDoc from '../../components/_UI/UIServiceDoc/UIServiceDoc'
import UIServiceName from '../../components/_UI/UIServiceName/UIServiceName'
import UIServicesRate from '../../components/_UI/UIServicesRate/UIServicesRate'
import UIServiceWeb from '../../components/_UI/UIServiceWeb/UIServiceWeb'
import WidgetHeader from '../../components/_Widget/WidgetHeader/WidgetHeader'
import WidgetServiceDisponibility from '../../components/_Widget/WidgetServiceDisponibility/WidgetServiceDisponibility'
import config from '../../config'
import { Service as ServiceGql } from '../../gql'
import { getUserRole } from '../../lib/helpers'
import './Services.scss'

const Services = () => {
  const { id } = useParams()
  const [titleService, setTitleService] = useState(undefined)
  const [descriptionService, setDescriptionService] = useState(undefined)
  const [webUrl, setWebUrl] = useState(undefined)
  const [urlHeader, setUrlHeader] = useState(undefined)
  const [serviceIcon, setServiceIcon] = useState(undefined)
  const [quantities, setQuantities] = useState([0, 0])
  const [total, setTotal] = useState(0)
  const [totalvat, setTotalVat] = useState(0)
  const [successPayment, setSuccessPayment] = useState(false)
  const [externalId, setExternalId] = useState()
  const access_token = localStorage.getItem('access_token')
  const infoUser = getUserRole(access_token)

  const headerImgSource = (files, id) => {
    if (files.find((file) => file === 'visuel.png')) {
      setUrlHeader(
        `${config.API_BASE_URL}/services/${id}/assets/visuel.png?token=${access_token}`
      )
    } else {
      setUrlHeader('/images/defaultHeader.png')
    }
  }

  const iconImgSource = (files, id) => {
    if (files.find((file) => file === 'picto.png')) {
      setServiceIcon(
        `${config.API_BASE_URL}/services/${id}/assets/picto.png?token=${access_token}`
      )
    } else {
      setServiceIcon('/images/defaultIcon.png')
    }
  }

  const { data: service } = useQuery(ServiceGql.getServiceData, {
    fetchPolicy: 'network-only',
    variables: {
      id: id,
      startDatetime: dayjs().format('YYYY-MM-DDT00:00:00+01:00'),
    },
    onCompleted: async () => {
      if (service && service.residenceService) {
        setTitleService(service?.residenceService?.serviceByIdService?.name)
        setDescriptionService(
          service?.residenceService?.serviceByIdService?.description
        )
        const files = await api.getFiles(
          service?.residenceService?.serviceByIdService?.id
        )
        headerImgSource(
          files,
          service?.residenceService?.serviceByIdService?.id
        )
        iconImgSource(files, service?.residenceService?.serviceByIdService?.id)
        setWebUrl(service?.residenceService?.serviceByIdService?.url)
        let article
        if (
          service?.residenceService?.catalogByIdResidentCatalogue
            ?.catalogArticlesByIdCatalog?.nodes
        ) {
          article =
            service?.residenceService?.catalogByIdResidentCatalogue
              ?.catalogArticlesByIdCatalog?.nodes
        } else if (
          service?.residenceService?.catalogByIdExternalCatalogue
            ?.catalogArticlesByIdCatalog?.nodes
        ) {
          article =
            service?.residenceService?.catalogByIdExternalCatalogue
              ?.catalogArticlesByIdCatalog?.nodes
        }
        if (article) {
          setQuantities(new Array(article?.length).fill(0))
        }
        if (
          service.residenceService.serviceByIdService
            .externalByIdServiceProvider
        )
          setExternalId(
            service.residenceService.serviceByIdService
              .externalByIdServiceProvider.id
          )
      }
    },
  })

  const changeQuantities = (index, quantity) => {
    const newQuantity = [...quantities]
    newQuantity[index] = parseInt(quantity)
    setQuantities(newQuantity)
  }

  useEffect(() => {
    let articles
    let sum = 0
    let vatsum = 0
    if (infoUser === 'logifim_resident') {
      if (
        service?.residenceService?.catalogByIdResidentCatalogue
          ?.catalogArticlesByIdCatalog?.nodes
      ) {
        articles =
          service?.residenceService?.catalogByIdResidentCatalogue
            ?.catalogArticlesByIdCatalog?.nodes
      }
    } else if (infoUser === 'logifim_external') {
      if (
        service?.residenceService?.catalogByIdExternalCatalogue
          ?.catalogArticlesByIdCatalog?.nodes
      ) {
        articles =
          service?.residenceService?.catalogByIdExternalCatalogue
            ?.catalogArticlesByIdCatalog?.nodes
      }
    }
    if (articles?.length > 0) {
      articles.forEach((element, index) => {
        sum +=
          element.articleByIdArticle.unitPricesByIdArticle.nodes[0].value *
          quantities[index]
        vatsum +=
          (element.articleByIdArticle.vatRateByIdVatRate.rate *
            element.articleByIdArticle.unitPricesByIdArticle.nodes[0].value *
            quantities[index]) /
          100
      })
    }
    setTotal(parseFloat(sum.toFixed(2)))
    setTotalVat(parseFloat(vatsum.toFixed(2)))
  }, [quantities])

  const onSuccessPayment = () => {
    setSuccessPayment(true)
  }

  return (
    <div className="services">
      <WidgetHeader />
      <div className="services_headerContainer">
        <img
          className="services_headerImg"
          src={urlHeader}
          alt={titleService}
        ></img>
      </div>
      <UIBackButton onClick={() => window.history.back()} />
      <div className="services_mainContainer">
        <UIServiceName
          title={titleService}
          iconSrc={serviceIcon}
        ></UIServiceName>
        {descriptionService !== undefined && (
          <UIServiceDescription
            description={descriptionService}
          ></UIServiceDescription>
        )}
        {service?.residenceService && (
          <UIServicesRate
            service={service?.residenceService}
            quantities={quantities}
            setQuantities={(index, quantity) => {
              changeQuantities(index, quantity)
            }}
            total={total}
            totalvat={totalvat}
            paymentSuccess={successPayment}
          ></UIServicesRate>
        )}
        {webUrl !== undefined ||
          (webUrl !== null && <UIServiceWeb webUrl={webUrl}></UIServiceWeb>)}
        {service?.residenceService && (
          <UIServiceDoc service={service?.residenceService}></UIServiceDoc>
        )}
        <WidgetServiceDisponibility
          service={service?.residenceService}
          webUrl={webUrl}
          idExternal={externalId}
          quantities={quantities}
          paymentSuccess={onSuccessPayment}
          total={total}
        ></WidgetServiceDisponibility>
      </div>
    </div>
  )
}

export default Services
