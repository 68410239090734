import PropTypes from 'prop-types'
import './UIServiceName.scss'

export default function UIServiceName({ title, iconSrc }) {
  return (
    <div className="uiServiceName">
      {title !== undefined && <p className="uiServiceName_title">{title}</p>}
      {iconSrc !== undefined && (
        <div className="uiServiceName_iconContainer">
          <img src={iconSrc}></img>
        </div>
      )}
    </div>
  )
}

UIServiceName.propTypes = {
  title: PropTypes.string.isRequired,
  iconSrc: PropTypes.string,
}
