/* eslint-disable no-unsafe-optional-chaining */
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getUserRole } from '../../../lib/helpers'
import { isFree, noPrice } from '../../../lib/services'
import UIInputNumber from '../../_UI/UIInputNumber/UIInputNumber'
import './UIServicesRate.scss'

export default function UIServicesRate({
  service,
  quantities,
  setQuantities,
  total,
  totalvat,
  paymentSuccess = false,
}) {
  const [price, setPrice] = useState([])
  const access_token = localStorage.getItem('access_token')
  const infoUser = getUserRole(access_token)
  const { t } = useTranslation('service')

  useEffect(() => {
    if (infoUser === 'logifim_resident') {
      const articles =
        service?.catalogByIdResidentCatalogue?.catalogArticlesByIdCatalog?.nodes
      if (articles) {
        setPrice(articles)
      }
    } else if (infoUser === 'logifim_external') {
      const articles =
        service?.catalogByIdExternalCatalogue?.catalogArticlesByIdCatalog?.nodes
      if (articles) {
        setPrice(articles)
      }
    }
  }, [service])

  if (noPrice(price)) return null

  return (
    <div
      className={`uiServicesRate ${
        price.length <= 1 && price[0]?.__typename !== 'CatalogArticle'
          ? 'uiServicesRate_onePrice'
          : ''
      }`}
    >
      {price[0]?.__typename === 'CatalogArticle' ? (
        <>
          <table className="uiServicesRate_table">
            <thead>
              <tr>
                <th>{t('article')}</th>
                <th>{t('price')}</th>
                <th>{t('quantity')}</th>
              </tr>
            </thead>
            <tbody>
              {price.map((list, index) => {
                if (list.articleByIdArticle.status !== 'DISABLED')
                  return (
                    <tr key={`list${index}`}>
                      <td>{list?.articleByIdArticle?.label}</td>
                      <td>
                        {isFree(
                          list?.articleByIdArticle?.unitPricesByIdArticle
                            ?.nodes[0]?.value
                        ) ? (
                          t('free')
                        ) : (
                          <>
                            {list?.articleByIdArticle?.unitPricesByIdArticle
                              ?.nodes[0]?.value / 100}
                            {
                              list?.articleByIdArticle?.unitPricesByIdArticle
                                ?.nodes[0]?.currencyByIdCurrency.symbol
                            }
                          </>
                        )}
                      </td>
                      <td className="quantity">
                        {list.articleByIdArticle.status !== 'UNAVAILABLE' &&
                        !paymentSuccess ? (
                          <UIInputNumber
                            value={quantities[index]}
                            onChange={(e) => setQuantities(index, e)}
                          />
                        ) : (
                          <p className="productQuantity">{t('unavailable')}</p>
                        )}
                      </td>
                    </tr>
                  )
              })}
            </tbody>
          </table>
          <p className="uiServicesRate_total">
            <span>{`${t('total')} :`}</span>
            {` ${total / 100} ${
              price[0]?.articleByIdArticle?.unitPricesByIdArticle?.nodes[0]
                ?.currencyByIdCurrency.symbol
            } ${t('allTaxIncluded')}
            `}
            <span className="totalvat">{` ${t('includingTax')}  ${totalvat} ${
              price[0]?.articleByIdArticle?.unitPricesByIdArticle?.nodes[0]
                ?.currencyByIdCurrency.symbol
            } `}</span>
          </p>
        </>
      ) : (
        <>
          {!isFree(price[0]) && (
            <>
              <p className="uiServicesRate_title">{t('price')}</p>
              <div>
                <p>{price[0]} €</p>
              </div>
            </>
          )}
        </>
      )}
    </div>
  )
}

UIServicesRate.defaultProps = {
  service: {},
}

UIServicesRate.propTypes = {
  service: PropTypes.objectOf(PropTypes.any),
  quantities: PropTypes.arrayOf(PropTypes.number).isRequired,
  setQuantities: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired,
  totalvat: PropTypes.number.isRequired,
  paymentSuccess: PropTypes.bool,
}
