import PropTypes from 'prop-types'
import './UIInputNumber.scss'

export default function UIInputNumber({
  label = '',
  placeholder = '',
  value,
  onChange,
  id = null,
  disable = false,
  min = 0,
  ...props
}) {
  const handleChange = (event) => {
    if (event.target.value >= min) {
      onChange(event.target.value)
    }
  }

  const addValue = () => {
    onChange(value + 1)
  }

  const subtractValue = () => {
    if (value - 1 >= min) onChange(value - 1)
  }

  return (
    <label className="uiInputNumber">
      {label && <div className="uiInputNumber_label">{label}</div>}
      <button
        type="button"
        className="uiInputNumber_button"
        onClick={() => subtractValue()}
      >
        -
      </button>
      <input
        type="number"
        min={min}
        className="uiInputNumber_field"
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        id={id}
        disabled={disable}
        {...props}
      />
      <button
        type="button"
        className="uiInputNumber_button"
        onClick={() => addValue()}
      >
        +
      </button>
    </label>
  )
}

UIInputNumber.propTypes = {
  label: PropTypes.string,
  value: PropTypes.number.isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string,
  disable: PropTypes.bool,
  min: PropTypes.number,
}
