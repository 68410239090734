import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import api from '../../../api'
import config from '../../../config'
import { useTranslation } from 'react-i18next'
import './UIServiceDoc.scss'

export default function UIServiceDoc({ service }) {
  const access_token = localStorage.getItem('access_token')
  const [files, setFiles] = useState([])
  const [loading, setLoading] = useState(true)
  const { t } = useTranslation('service')
  useEffect(async () => {
    if (service?.serviceByIdService?.id) {
      const files = await api.getFiles(service?.serviceByIdService?.id)
      setFiles(
        files.filter((file) => ['picto.png', 'visuel.png'].indexOf(file) === -1)
      )
      setLoading(false)
    }
  }, [service])
  return (
    <>
      {files.length > 0 && !loading && (
        <div className="uiServiceDoc">
          <p className="uiServiceDoc_title">{t('fileTitle')}</p>
          {files.map((file, index) => (
            <div key={`file${index}`}>
              <a
                href={`${config.API_BASE_URL}/services/${service?.serviceByIdService?.id}/assets/${file}?token=${access_token}`}
                target="_blank"
                rel="noopener noreferrer"
                className="uiServiceDoc_name"
              >
                {file}
              </a>
            </div>
          ))}
        </div>
      )}
    </>
  )
}

UIServiceDoc.defaultProps = {
  service: {},
}

UIServiceDoc.propTypes = {
  service: PropTypes.objectOf(PropTypes.any),
}
