import React from 'react'
import { useTranslation } from 'react-i18next'
import WidgetHeader from '../../components/_Widget/WidgetHeader/WidgetHeader'
import UIBackButton from '../../components/_UI/UIBackButton/UIBackButton'
import UIHeaderForm from '../../components/_UI/UIHeaderForm/UIHeaderForm'
import WidgetAccordionFaq from '../../components/_Widget/WidgetAccordionFaq/WidgetAccordionFaq'
import './Faq.scss'
import { useHistory } from 'react-router-dom'
import UIButton from '../../components/_UI/UIButton/UIButton'
import { useQuery } from '@apollo/client'
import { Faqs } from '../../gql'
import { useState } from 'react'

const Faq = () => {
  const history = useHistory()
  const { t } = useTranslation('faq')
  const [faqQuestion, setFaqQuestion] = useState([])

  const { data: faqs } = useQuery(Faqs.getFaqs, {
    onCompleted: (data) => {
      const array = []
      data.faqs.nodes.map((item) =>
        array.push({
          title: item.question,
          content: item.answer,
          pos: item.pos,
        })
      )
      setFaqQuestion(array.sort((a, b) => a.pos - b.pos))
    },
  })

  console.log({ faqs })

  const goToContactForm = () => {
    history.push('/contact-form')
  }

  return (
    <div className="faq">
      <WidgetHeader />
      <UIBackButton onClick={() => window.history.back()}></UIBackButton>
      <UIHeaderForm title={t('title')} subTitle={t('subTitle')}></UIHeaderForm>
      <div className="faq_accordion">
        {faqQuestion.map(({ title, content }) => (
          <WidgetAccordionFaq
            key={title}
            title={title}
            content={content}
          ></WidgetAccordionFaq>
        ))}
      </div>
      <p className="faq_dsc">{t('noAnswer')}</p>
      <div className="faq_btn">
        <UIButton
          label={t('btnLabel')}
          size="full"
          backgroundColor="bgWhite"
          color="green"
          onClick={goToContactForm}
        />
      </div>
    </div>
  )
}

export default Faq
