import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import './UIBackButton.scss'

export default function UIBackButton({ onClick }) {
  const { t } = useTranslation('backButton')
  return (
    <button
      type="button"
      className="comeBackButton"
      onClick={onClick}
      alt={t('altBack')}
    >
      <svg
        width="9"
        height="13"
        viewBox="0 0 9 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.36621 1.98535L2.36621 6.98535L7.36621 11.9854"
          stroke="#485156"
          strokeWidth="2"
          strokeLinecap="round"
        />
      </svg>
      <p className="comeBackButton_text">{t('back')}</p>
    </button>
  )
}

UIBackButton.propTypes = {
  onClick: PropTypes.func.isRequired,
}
