import React, { useState } from 'react'
import WidgetHeader from '../../components/_Widget/WidgetHeader/WidgetHeader'
import UIBackButton from '../../components/_UI/UIBackButton/UIBackButton'
import './MyResidence.scss'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import { getUserId, getUserRole } from '../../lib/helpers'
import { External, Resident } from '../../gql'
import { captureMessageWithAttachment } from '../../lib/sentry'
import MyResidenceForm from '../../components/MyResidenceForm/MyResidenceForm'
import api from '../../api'

const MyResidence = () => {
  const { t } = useTranslation('myResidence')
  const access_token = localStorage.getItem('access_token')
  const infoUser = getUserRole(access_token)

  const [residentNumber, setResidentNumber] = useState('')
  const [lastname, setLastname] = useState('')
  const [firstname, setFirstname] = useState('')
  const [email, setEmail] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [birthdate, setBirthdate] = useState('')
  const [residenceImage, setResidenceImage] = useState()

  let userRequest
  if (infoUser === 'logifim_resident') {
    userRequest = Resident.RESIDENT
  } else if (infoUser === 'logifim_external') {
    userRequest = External.EXTERNAL
  }

  const { data: user } = useQuery(userRequest, {
    fetchPolicy: 'network-only',
    variables: {
      id: getUserId(access_token),
    },
    onCompleted: () => {
      api
        .getResidenceFile(
          user.resident.idResidence,
          localStorage.getItem('access_token'),
          'visuel.png'
        )
        .then((response) => {
          let blob = new Blob([response.data], {
            type: response.headers['content-type'],
          })
          let imgUrl = URL.createObjectURL(blob)
          setResidenceImage(imgUrl)
        })
      if (user) {
        let usr
        if (infoUser === 'logifim_resident') {
          usr = user.resident
        } else if (infoUser === 'logifim_external') {
          usr = user.external
        }

        if (usr) {
          setResidentNumber(usr.residentNumber)
          setLastname(
            usr.residenceByIdResidence.residenceCoordinationsByIdResidence
              .nodes[0].staffByIdCoordinator.lastname
          )
          setFirstname(
            usr.residenceByIdResidence.residenceCoordinationsByIdResidence
              .nodes[0].staffByIdCoordinator.firstname
          )
          setEmail(
            usr.residenceByIdResidence.residenceCoordinationsByIdResidence
              .nodes[0].staffByIdCoordinator.email
          )
          setPhoneNumber(
            usr.residenceByIdResidence.residenceCoordinationsByIdResidence
              .nodes[0].staffByIdCoordinator.mobilePhoneNumber
          )
          setBirthdate(usr.birthdate)
        }
      }
    },
    onError: (err) => {
      const event = {
        request: userRequest?.definitions[0]?.name?.value,
        variables: {
          id: getUserId(access_token),
        },
      }
      captureMessageWithAttachment(event, err)
    },
  })

  const downloadResidentRules = () => {
    api
      .getResidenceFile(
        user.resident.idResidence,
        localStorage.getItem('access_token'),
        'rulesfiles'
      )
      .then((response) => {
        if (response && response.status === 200) {
          const file = response
          const f = new Blob([file.data], {
            type: file.headers['content-type'],
          })
          const a = document.createElement('a')
          const fileURL = URL.createObjectURL(f)
          a.href = fileURL
          a.target = '_blank'
          a.download = t('residentRules')
            .split(';')[0]
            .replace(/[&/\\#,+()$~%.'":*?<>{}]/g, '')
          document.body.appendChild(a)
          a.click()
        }
      })
  }

  const downloadResidentUseConditions = () => {
    api
      .getResidenceFile(
        user.resident.idResidence,
        localStorage.getItem('access_token'),
        'conditionfiles'
      )
      .then((response) => {
        if (response && response.status === 200) {
          const file = response
          const f = new Blob([file.data], {
            type: file.headers['content-type'],
          })
          const a = document.createElement('a')
          const fileURL = URL.createObjectURL(f)
          a.href = fileURL
          a.target = '_blank'
          a.download = t('residentUse')
            .split(';')[0]
            .replace(/[&/\\#,+()$~%.'":*?<>{}]/g, '')
          document.body.appendChild(a)
          a.click()
        }
      })
  }

  return (
    <div>
      <WidgetHeader />
      <div className="myAccount_back">
        <UIBackButton onClick={() => window.history.back()} />
      </div>
      {infoUser === 'logifim_resident' && (
        <div className="myResidence">
          <img
            className="myResidence_icon"
            src="icons/house.svg"
            alt={t('altHouse')}
          />
          <div className="myResidence_informations">
            <p className="myResidence_informations_title">{t('MyResidence')}</p>
            {user?.resident?.residenceByIdResidence?.name && (
              <p className="myResidence_informations_text myResidence_informations_text-bold">
                {user?.resident?.residenceByIdResidence?.name}
              </p>
            )}
            {user?.resident?.building && (
              <p className="myResidence_informations_text myResidence_informations_text-bold">
                {`${user?.resident?.building}`}
              </p>
            )}

            {user?.resident?.residenceByIdResidence?.address && (
              <p className="myResidence_informations_text myResidence_informations_text-bold">
                {user?.resident?.residenceByIdResidence?.address}
              </p>
            )}
            {user?.resident?.residenceByIdResidence?.cityByIdCity?.postcode &&
              user?.resident?.residenceByIdResidence?.cityByIdCity?.name && (
                <p className="myResidence_informations_text myResidence_informations_text-bold">
                  {`${user?.resident?.residenceByIdResidence?.cityByIdCity?.postcode} ${user?.resident?.residenceByIdResidence?.cityByIdCity?.name}`}
                </p>
              )}
          </div>
          <img
            className="residenceImage"
            src={residenceImage && residenceImage}
            alt={t('residentAltImage')}
          />
        </div>
      )}
      <MyResidenceForm
        residentNumber={residentNumber}
        lastnameValue={lastname}
        firstnameValue={firstname}
        emailValue={email}
        phoneNumberValue={phoneNumber}
        birthdayValue={birthdate}
        downloadResidentRules={downloadResidentRules}
        downloadResidentUseConditions={downloadResidentUseConditions}
      />
    </div>
  )
}

export default MyResidence
