import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import './UIChevronButton.scss'

export default function UIChevronButton({ onClick, open, title, color }) {
  const { t } = useTranslation('chevronButton')
  return (
    <button
      type="button"
      className="chevronButton"
      style={{ color: color }}
      onClick={onClick}
      alt={t(open)}
    >
      <h1>{title}</h1>
      <svg
        className={`chevronButton--${open}`}
        width="9"
        height="13"
        viewBox="0 0 9 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.36621 1.98535L2.36621 6.98535L7.36621 11.9854"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
        />
      </svg>
    </button>
  )
}

UIChevronButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  open: PropTypes.func.isRequired,
  title: PropTypes.string,
  color: PropTypes.string,
}

UIChevronButton.defaultProps = {
  title: '',
  color: 'black',
}
