import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import UIButton from '../_UI/UIButton/UIButton'
import './Reservation.scss'

export default function Reservation({
  reservation,
  opened,
  cancel,
  stripeTicket,
}) {
  const { t } = useTranslation('reservation')

  return (
    <>
      <div className="reservation">
        <button
          type="button"
          className="reservation_text"
          onClick={() => opened()}
        >
          <div className="reservation_desc">
            <img
              className="reservation_icon"
              src="icons/basket.svg"
              alt={t('altIcon')}
            />
            <p className="reservation_title">
              {
                reservation?.residenceServiceByIdResidenceService
                  ?.serviceByIdService?.name
              }
            </p>
          </div>
          <p className="reservation_infocontainer">
            <img
              className="reservation_info"
              src="icons/info.svg"
              alt={t('altInfo')}
            />
          </p>
        </button>
      </div>
      {reservation.opened && (
        <div className="reservationOpen">
          <p className="reservationOpen_label">
            {t('reservationDateLabel')}{' '}
            <span className="reservationOpen_date">
              {dayjs(reservation?.createdAt).format('DD/MM/YYYY')}
            </span>
          </p>
          <p className="reservationOpen_label">
            {t('from')}{' '}
            <span className="reservationOpen_date">
              {dayjs(reservation?.startDatetime).format('DD/MM/YYYY')}
            </span>
            {` ${t('to')} `}
            <span className="reservationOpen_date">
              {dayjs(reservation?.endDatetime).format('DD/MM/YYYY')}
            </span>
          </p>
          {reservation?.orderArticlesByIdOrder?.nodes?.length ? (
            <>
              <p className="reservationOpen_label">{`${t('ordered')} :`}</p>
              <ul className="reservationOpen_list">
                {reservation?.orderArticlesByIdOrder.nodes.map((article) => (
                  <li
                    key={article.id}
                  >{`${article.quantity} ${article.articleByIdArticle.label}`}</li>
                ))}
              </ul>
              <p className="reservationOpen_label">
                {t('price')}{' '}
                <span className="reservationOpen_date">{`${
                  reservation?.totalAmount / 100
                } €`}</span>
              </p>
            </>
          ) : null}
          {reservation?.residenceServiceByIdResidenceService?.residentPrice && (
            <p className="reservationOpen_label">
              {t('price')}{' '}
              <span className="reservationOpen_date">
                {`${
                  reservation?.residenceServiceByIdResidenceService
                    ?.residentPrice / 100
                } €`}
              </span>
            </p>
          )}
          <p className="reservationOpen_label">
            {`${t('status')} : `}
            <span className="reservationOpen_date">
              {t(reservation?.status)}
            </span>
          </p>
          <div className="buttonContainer">
            {(reservation?.status === 'NEW' ||
              (reservation?.status === 'PENDING' && !stripeTicket)) && (
              <UIButton
                label={t('cancel')}
                backgroundColor="bgRed"
                onClick={() => cancel(reservation?.id)}
              />
            )}
            {stripeTicket && (
              <UIButton
                backgroundColor="bgGreen"
                label={
                  <a
                    href={stripeTicket}
                    target="_blank"
                    rel="ticket noreferrer"
                    className="stripeTicketLink"
                  >
                    {t('stripe')}
                  </a>
                }
              />
            )}
          </div>
        </div>
      )}
    </>
  )
}

Reservation.propTypes = {
  reservation: PropTypes.object.isRequired,
  opened: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  stripeTicket: PropTypes.string,
}
