import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  from,
  InMemoryCache,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import i18n from 'i18next'
import { PropTypes } from 'prop-types'
import React from 'react'
import { positions, Provider as AlertProvider } from 'react-alert'
import { render } from 'react-dom'
import { initReactI18next } from 'react-i18next'
import { BrowserRouter, Redirect } from 'react-router-dom'
import api from './api'
import App from './App'
import config from './config'
import resources from './i18n'
import { isUserTokenExpired } from './lib/helpers'
import * as serviceWorker from './serviceWorker'

const isLocal =
  window.location.hostname === 'localhost' ||
  window.location.hostname === '127.0.0.1'

const httpLink = createHttpLink({
  uri: config.GRAPHQL_URL,
})

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const accessToken = localStorage.getItem('access_token')
  const refreshToken = localStorage.getItem('refresh_token')
  if (!accessToken) {
    return {
      headers,
    }
  }
  if (accessToken && isUserTokenExpired(accessToken)) {
    api
      .refreshAccessToken(refreshToken)
      .then((response) => {
        localStorage.setItem('access_token', response.access_token)
        localStorage.setItem('refresh_token', response.refresh_token)
      })
      .then(() => {
        return {
          headers: {
            ...headers,
            authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      })
      .catch(() => <Redirect to="/login" />)
  } else {
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${accessToken}`,
      },
    }
  }
  // return the headers to the context so httpLink can read them
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    )

  if (networkError) console.log(`[Network error]: ${networkError}`)
})

const client = new ApolloClient({
  connectToDevTools: isLocal ? true : false, // ternaire pour dev mettre true sinon false
  link: from([errorLink, authLink.concat(httpLink)]),
  cache: new InMemoryCache(),
})

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: localStorage.getItem('key') || 'fr',
    fallbackLng: 'en',

    interpolation: {
      escapeValue: false,
    },
  })

window.addEventListener('beforeinstallprompt', (e) => {
  window.bcdDeferredPrompt = e
})

const options = {
  position: positions.TOP_CENTER,
}

const AlertTemplate = ({ message }) => (
  <div
    style={{
      display: 'flex',
      height: 40,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#fff',
      padding: 20,
    }}
  >
    {message}
  </div>
)

render(
  <ApolloProvider client={client}>
    <BrowserRouter>
      <AlertProvider template={AlertTemplate} {...options}>
        <App />
      </AlertProvider>
    </BrowserRouter>
  </ApolloProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()

AlertTemplate.propTypes = {
  message: PropTypes.string,
}
