import React from 'react'
import { useTranslation } from 'react-i18next'
import UIBackButton from '../../components/_UI/UIBackButton/UIBackButton'
import UIHeaderForm from '../../components/_UI/UIHeaderForm/UIHeaderForm'
import WidgetFormContact from '../../components/_Widget/WidgetFormContact/WidgetFormContact'
import WidgetHeader from '../../components/_Widget/WidgetHeader/WidgetHeader'

const ContactForm = () => {
  const { t } = useTranslation('contactForm')

  const optionContact = [
    { name: t('select.default'), id: 1 },
    { name: t('select.accommodation'), id: 2 },
    { name: t('select.residence'), id: 3 },
    { name: t('select.service'), id: 4 },
    { name: t('select.other'), id: 5 },
  ]

  return (
    <div className="incidentForm">
      <WidgetHeader />
      <UIBackButton onClick={() => window.history.back()} />
      <UIHeaderForm title={t('title')} subTitle={t('subTitle')} />
      <WidgetFormContact
        options={optionContact}
        placeholder={t('placeholder')}
        dsc={t('dsc')}
        fromIncident={false}
        validationMessage={t('send')}
      />
    </div>
  )
}

export default ContactForm
