import React, { useState } from 'react'
import { PropTypes } from 'prop-types'
import './WidgetAccordionFaq.scss'
import {
  MdOutlineKeyboardArrowRight,
  MdOutlineKeyboardArrowDown,
} from 'react-icons/md'

const WidgetAccordionFaq = ({ title, content }) => {
  const [isActive, setIsActive] = useState(false)

  return (
    <div className="widgetAccordionFaq">
      <div className="widgetAccordionFaq_item">
        <div
          className="widgetAccordionFaq_title"
          onClick={() => setIsActive(!isActive)}
        >
          <div>{title}</div>
          <div>
            {isActive ? (
              <MdOutlineKeyboardArrowDown></MdOutlineKeyboardArrowDown>
            ) : (
              <MdOutlineKeyboardArrowRight></MdOutlineKeyboardArrowRight>
            )}
          </div>
        </div>
        {isActive && (
          <div className="widgetAccordionFaq_content">{content}</div>
        )}
      </div>
    </div>
  )
}

WidgetAccordionFaq.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
}

export default WidgetAccordionFaq
